
















import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {
    DraggableResizable: () => import('@/components/UI/DraggableResizable.vue'),
    ImportChatDialog: () => import('./Dialog/index.vue'),
  },
})
export default class TheImportChatMd extends Vue {
  private minimize = false

  get startX () {
    return this.minimize
      ? 0
      : window.innerWidth / 2 - this.dialogWidth / 2
  }

  get startY () {
    return this.minimize
      ? 0
      : window.innerHeight / 2 - this.dialogHeight / 2
  }

  get dialogWidth () {
    return this.minimize ? 417 : 999
  }

  get dialogHeight () {
    return this.minimize ? 72 : 700
  }

  get minHeight () {
    return this.minimize ? this.dialogHeight : 368
  }

  get minWidth () {
    return this.minimize ? this.dialogWidth : 352
  }
}
